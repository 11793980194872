<template>
  <div>
    <div class="about-us-top">
      <div class="container">
          <div class="about-us-in no-bg">
              <div class="about-left navigation-button">
                  <h1>Contact Us</h1>
                  <p>Leave a message and we will typically reply before your tea gets cold.</p>               
              </div>
              <div class="about-right">
                  <p v-if="errors.length" class="error_message">
                    <b>Please correct the following error(s):</b>
                    <ul>
                      <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                  </p>
                  <form ref="form" @submit.prevent="sendEmail">
                      <div class="input-wrapper">
                          <input type="text" id="user" name="name" v-model="name" class="form-control" required>
                          <label for="user" class="form-control-placeholder">Name</label>
                      </div>
                      <div class="input-wrapper">
                          <input type="email" name="email" v-model="email" class="form-control" email required>
                          <label for="user" class="form-control-placeholder">Email address</label>
                      </div>
                      <div class="input-wrapper">
                          <input type="number" name="phone" v-model="phone" class="form-control" required>
                          <label for="user" class="form-control-placeholder">Phone number</label>
                      </div>
                      <div class="input-wrapper">
                          <input type="number" name="contact_phone" v-model="contact_phone" class="form-control" required>
                          <label for="user" class="form-control-placeholder">Contact Phone number</label>
                      </div>
                      <div class="input-wrapper">
                          <textarea name="message" v-model="message" class="form-control" required></textarea>
                          <label for="user" class="form-control-placeholder"  >Message</label>
                      </div>
                      <div class="input-wrapper custom-checkbox">
                          <input type="checkbox" name="accept" id="accept" v-model="accept" class="form-control" required>
                          <label for="accept">By contacting us, you agree to receive informational text messages. You may opt out of receiving text messages (SMS) at any time by replying to STOP to any message.
                          </label>
                      </div>

                      <div class="form-group">
                          <input type="submit" class="submit" value="Send Message" :disabled=processing>
                      </div>
                      <div class="loader">
                        <img src="../assets/images/loader.gif" alt="" :class="{'show' : this.processing}">
                        <div class="success-msg">
                          <p :class="{'show' : this.success}"> Thank you for submission.</p>
                        </div>
                      </div>
                  </form>
              </div>
          </div>
      </div>    
  </div>
  <div class="service-detail">
      <div class="container">
          <div class="service-data">
              <div class="service-box-in">
                  <h3>CALL CENTRE</h3>
                  <ul>
                      <li>Give us a free call</li>
                      <li><a href="tel:+1 (317) 536-8005" class="c-f">+1 (317) 536-8005</a></li>
                      <li>By contacting us, you agree to receive informational text messages. You may opt out of receiving text messages (SMS) at any time by replying to STOP to any message.</li>
                  </ul>
              </div>
              <div class="service-box-in">
                  <h3>WORKING HOURS</h3>
                  <ul>
                      <li>MON - SUN : 9:00AM - 6:00PM</li>
                      <li><i>Emergency can call helpline</i></li>
                  </ul>
              </div>
              <div class="service-box-in">
                  <h3>LOCATION</h3>
                  <ul>
                      <li class="c-f">2800 Bluff Rd</li>
                      <li class="c-f">Indianapolis, IN, 46225</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'Contact',
  data(){
        return {
          errors: [],
          success: false,
          processing: false,
          name: null,
          email: null,
          phone: null,
          contact_phone: null,
          message: null,
          accept: null
        }
  },
  methods: {

    ValidateForm: function () {
      
      this.errors = [];

      if (!this.name) {
        this.errors.push('Name required.');
      }
      if (!this.email) {
        this.errors.push('Email required.');
      }
      if (!this.phone) {
        this.errors.push('Phone required.');
      }
      if (!this.contact_phone) {
        this.errors.push('Contact Phone required.');
      }
      if (!this.message) {
        this.errors.push('Message required.');
      }

      if (!this.errors.length) {
        return true;
      }
    },

    sendEmail() {
      this.processing = true;
      if(this.ValidateForm()){
        emailjs.sendForm('service_3jmtejb', 'template_b8bdlg8', this.$refs.form,'EFDDawqYuA7FZA8uP')
          .then(() => {
              this.processing = false;
              this.success = true;
              this.$refs.form.reset();
              setTimeout(() => {
                this.success = true;
              }, 5000)
          }, (error) => {
              this.processing = false;
              this.errors.push(error.response.data.message);
        });
      }
      this.processing = false;
    }
  }
}
</script>